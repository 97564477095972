/* eslint-disable no-empty-pattern */
import FormsApi from '@/api/formPublic'
import FormPublicApi from '@/api/formPublic/formPublic'
import uiHelper from '@/helpers/ui'
import { EventBus } from '@/eventBus.js'

export default {
  namespaced: true,

  state: {
    selectedFormId: '',
    selectedForm: {},
    allSubmissions: [],
    selectedSubmission: {},
    selectedSubmissionToken: '',
    selectedSubmissionTokens: [],
    selectedSubmissionStats: null,
    selectedSubmissionSection: {},
    selectedSubmissionSummary: {},
    selectedSubmissionSubmitted: false,
    selectedSubmissionDetails: {},
    isSelectedSubmissionOnHold: false,
    submissionPageNumber: 1,
    submissionTotalPagesCount: 0,
    formType: '',
    formBaseURL: ''
  },

  getters: {
    selectedForm: state => state['selectedForm'],
    selectedFormId: state => state['selectedFormId'],
    allSubmissions: state => state['allSubmissions'],
    selectedSubmission: state => state['selectedSubmission'],
    selectedSubmissionToken: state => state['selectedSubmissionToken'],
    selectedSubmissionTokens: state => state['selectedSubmissionTokens'],
    selectedSubmissionStats: state => state['selectedSubmissionStats'],
    selectedSubmissionDetails: state => state['selectedSubmissionDetails'],
    selectedSubmissionSections(state) {
      const selectedSubmission = state['selectedSubmission']

      return selectedSubmission.form_sections
    },
    selectedSubmissionSection: state => state['selectedSubmissionSection'],
    selectedSubmissionSummary: state => state['selectedSubmissionSummary'],
    selectedSubmissionSubmitted: state => state['selectedSubmissionSubmitted'],
    isSelectedSubmissionOnHold: state => state['isSelectedSubmissionOnHold'],
    isSubmissionSubmitted: () => false,
    submissionPageNumber: state => state.submissionPageNumber,
    submissionTotalPagesCount: state => state.submissionTotalPagesCount,
    selectedSectionQuestions(state) {
      if (state.selectedSubmissionSection.hasOwnProperty('questions'))
        return state.selectedSubmissionSection.questions
      return []
    },
    formType: state => state.formType,
    formBaseURL: state => state.formBaseURL
  },

  mutations: {
    setSelectedFormId(state, id) {
      state.selectedFormId = id
    },

    setSelectedForm(state, form) {
      state.selectedForm = form
    },

    setAllSubmissions(state, submissions) {
      state.allSubmissions = submissions
    },

    addToAllSubmissions(state, submissions) {
      state.allSubmissions.push.apply(state.allSubmissions, submissions)
    },

    clearAllSubmissions(state) {
      state.allSubmissions = []
    },

    setSelectedSubmission(state, submission) {
      state.selectedSubmission = submission
    },

    setSelectedSubmissionDetails(state, data) {
      state.selectedSubmissionDetails = data
    },

    setSelectedSubmissionToken(state, token) {
      state.selectedSubmissionToken = token
    },

    setSelectedSubmissionTokens(state, tokens) {
      state.selectedSubmissionTokens = tokens
    },

    setSelectedSubmissionStats(state, stats) {
      state.selectedSubmissionStats = stats
    },

    setSelectedSubmissionSection(state, section) {
      state.selectedSubmissionSection = section
    },

    setSelectedSubmissionSummary(state, summary) {
      state.selectedSubmissionSummary = summary
    },

    setIsSubmissionSubmitted(state, status) {
      state.isSubmissionSubmitted = status
    },

    setSubmissionTotalPagesCount(state, count) {
      state.submissionTotalPagesCount = count
    },

    setSubmissionPageNumber(state, pageNumber) {
      state.submissionPageNumber = pageNumber
    },

    setSelectedSubmissionOnHoldStatus(state, status) {
      state.isSelectedSubmissionOnHold = status
    },

    setFormType(state, type) {
      state.formType = type
    },

    resetSelectedForm(state) {
      state.selectedSubmission = {}
      state.selectedSubmissionToken = ''
      state.selectedSubmissionStats = null
      state.selectedSubmissionSection = {}
      state.selectedSubmissionSummary = {}
      // state.selectedSubmissionSubmitted = false
      state.selectedSubmissionDetails = {}
      state.isSelectedSubmissionOnHold = false
    }
  },

  actions: {
    async getOpenSubmissions() {
      uiHelper.progressBarStatus(true)
      let response
      return response
    },

    async createNewSubmission() {
      uiHelper.progressBarStatus(true)
      let response
      return response
    },

    async getSectionResponses(
      { state },
      { sectionId = state.selectedSubmissionSection.id }
    ) {
      uiHelper.progressBarStatus(true)

      try {
        let response
        response = await FormsApi.getSectionResponses(sectionId)
        return response.data.payload
      } catch (error) {
        throw error
      } finally {
        uiHelper.progressBarStatus(false)
      }
    },

    async submitSingleAnswer({}, { data }) {
      try {
        let response
        response = await FormPublicApi.submitAnswer(data)
        if (response.data.payload.hasOwnProperty('calculated'))
          EventBus.$emit('refOption', response.data.payload.calculated)
      } catch (error) {
        uiHelper.showSnackbarMessage(error.response.data.msg, 'error')
        if (error.response.data) {
          if (error.response.data.msg.hasOwnProperty('answer')) {
            let answer = error.response.data.msg.answer
            // eslint-disable-next-line no-unused-vars
            for (let i in answer) {
              // eslint-disable-next-line no-unused-vars
              for (let j in answer[i]) {
                for (let k = 0; k < answer[i][j].length; k++) {
                  uiHelper.showSnackbarMessage(
                    `${j} ${answer[i][j][k]}`,
                    'error'
                  )
                }
              }
            }
          }
        }
        throw error
      }
    },

    async submitMultipleAnswers({ getters }, { data }) {
      try {
        await FormPublicApi.submitAnswer(
          data,
          getters['selectedSubmissionToken']
        )
      } catch (error) {
        uiHelper.showSnackbarMessage(error.response.data.msg, 'error')
        throw error
      }
    },

    async getSubmissionStats() {},

    async getSummary({ commit }) {
      uiHelper.progressBarStatus(true)

      try {
        const response = await FormsApi.getSubmissionSummary()

        commit('setSelectedSubmissionSummary', response.data.payload)
      } catch (error) {
        uiHelper.showSnackbarMessage(error.response.data.msg, 'error')
        throw error
      } finally {
        uiHelper.progressBarStatus(false)
      }
    },

    async startNewFormSubmission({ commit }, { formId }) {
      try {
        const response = await FormPublicApi.startNewFormSubmission({
          formId
        })
        commit(
          'setSelectedSubmissionToken',
          response.data.payload.submission_token
        )
        sessionStorage.setItem(
          'submissionToken',
          response.data.payload.submission_token
        )

        // Setting up empty array of questions in every section
        let submission = response.data.payload.form
        let sections = submission.sections.filter(
          el => el.type === 'normal' || el.type === 'summary'
        )
        submission.sections = sections
        submission.sections = submission.sections.map(section => ({
          ...section,
          questions: []
        }))
        commit('setSelectedSubmission', submission)
        commit('setSelectedFormId', response.data.payload.form.id)

        commit(
          'setSelectedSubmissionSection',
          response.data.payload.form.sections[0]
        )
      } catch (err) {
        commit('setSelectedSubmission', {})
        commit('setSelectedFormId', '')

        commit('setSelectedSubmissionSection', {})
        uiHelper.showSnackbarMessage(err.response.data.msg, 'error')
      }
    },

    async getSubmissionDetails(
      { commit },
      { formId, submissionToken, clientToken }
    ) {
      let payload
      try {
        const response = await FormPublicApi.getSubmissionDetails({
          formId,
          submissionToken,
          clientToken
        })
        payload = response.data.payload
        commit('setSelectedSubmissionToken', submissionToken)
        let submission = response.data.payload.form
        submission.sections = submission.sections.map(section => ({
          ...section,
          questions: []
        }))
        commit('setSelectedSubmission', submission)
        commit('setSelectedFormId', response.data.payload.form.id)

        commit(
          'setSelectedSubmissionSection',
          response.data.payload.form.sections[0]
        )
      } catch (error) {
        commit('setSelectedSubmission', {})
        commit('setSelectedFormId', '')

        commit('setSelectedSubmissionSection', {})
        throw error
      }
      return payload
    },

    async getQuestionsBySection({ state, commit }) {
      uiHelper.progressBarStatus(true)
      let response
      try {
        if (!state.selectedSubmissionSection.questions.length) {
          response = await FormsApi.getQuestionsBySection(
            state.selectedSubmission.id,
            state.selectedSubmissionSection.id
          )

          let selectedSubmission = state.selectedSubmission

          const selectedSectionIndex = selectedSubmission.sections.findIndex(
            section => section.id === state.selectedSubmissionSection.id
          )

          selectedSubmission.sections[selectedSectionIndex].questions =
            response.data.payload.questions
          await commit('setSelectedSubmission', selectedSubmission)
        }
      } catch (err) {
        uiHelper.showSnackbarMessage(err.response.data.msg, 'error')
      } finally {
        uiHelper.progressBarStatus(false)
      }
    },

    async generatePDF({}, submissionToken) {
      try {
        const response = await FormsApi.generatePDF(submissionToken)
        uiHelper.showSnackbarMessage(response.data.msg)
      } catch (error) {
        uiHelper.showSnackbarMessage(error.response.data.msg, 'error')
        throw error
      }
    }
  }
}
