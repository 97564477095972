import client from './_client'
import store from '../../store'

const API_TOKEN = process.env.VUE_APP_API_TOKEN

export default {
  getBusinessDetails(businessId) {
    return client({
      method: 'GET',
      url: `/api/v1/businesses/${businessId}?withBranding=true`,
      headers: {
        'auth-token': store.getters['auth/authToken'],
        'api-token': API_TOKEN
      }
    })
  },
  getBusinessDetailsBySlug(businessSlug) {
    return client({
      method: 'GET',
      url: `api/v1/businesses/slug/${businessSlug}?withBranding=true&rgba=false`,
      headers: {
        'auth-token': store.getters['auth/authToken'],
        'api-token': API_TOKEN
      }
    })
  }
}
