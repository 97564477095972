import client from './_client'
import store from '../../store'

export default {
  getOpenSubmissions(
    submission_tokens,
    formId,
    table_headers,
    page,
    searchTerm
  ) {
    return client({
      method: 'POST',
      url: `/v2/submissions/${formId}/open?page=${page}&limit=10&search=${searchTerm}`,
      headers: {
        'client-token': sessionStorage.getItem('clientToken')
      },
      data: {
        submission_tokens,
        table_headers
      }
    })
  },

  getQuestionsBySection(formId, sectionId) {
    return client({
      method: 'GET',
      url: `/api/v1/submissions/forms/${formId}/?section_id=${sectionId}`,
      headers: {
        'client-token': sessionStorage.getItem('clientToken'),
        'submission-token': sessionStorage.getItem('submissionToken')
      }
    })
  },

  getResponsesBySection(sectionId) {
    return client({
      method: 'GET',
      url: `/api/v1/responses/?section_id=${sectionId}`,
      headers: {
        'client-token': sessionStorage.getItem('clientToken'),
        'submission-token': sessionStorage.getItem('submissionToken')
      }
    })
  },

  // getSubmissionDetails(formId) {
  getSectionResponses(section_id) {
    return client({
      method: 'GET',
      // url: `/api/v1/respondents/forms/${formId}/submission`,
      url: `/api/v1/responses/?section_id=${section_id}`,
      headers: {
        'client-token': sessionStorage.getItem('clientToken'),
        'submission-token': sessionStorage.getItem('submissionToken'),
        'auth-token': store.getters['auth/authToken']
      }
    })
  },

  getSubmissionSummary() {
    return client({
      method: 'GET',
      // url: `/api/v1/respondents/forms/${store.getters['form/selectedFormId']}/summary`,
      url: `api/v1/submissions/forms/${store.getters['form/selectedFormId']}/summary`,
      headers: {
        'client-token': sessionStorage.getItem('clientToken'),
        'submission-token': sessionStorage.getItem('submissionToken')
      }
    })
  },

  getArchivedSubmissions(formId, table_headers) {
    return client({
      method: 'POST',
      url: `/v2/submissions/${formId}/open`,
      headers: {
        'client-token': sessionStorage.getItem('clientToken')
      },
      data: {
        table_headers,
        is_completed: true
      }
    })
  },

  generatePDF(submissionToken) {
    return client({
      method: 'GET',
      url: `/api/v1/pdf/submissions/${submissionToken}`,
      headers: {
        'submission-token': submissionToken
      }
    })
  }
}
