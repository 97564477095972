import store from '../store'

export default {
  // Show snackbar with message
  showSnackbarMessage(text, color = 'success', timeout = 3000) {
    store.commit(
      'ui/showSnackbar',
      {
        visible: true,
        text,
        timeout,
        color
      },
      { root: true }
    )
  },
  // Show/Hide progress bar
  progressBarStatus(status) {
    store.commit('ui/setProgressBarStatus', status, { root: true })
  }
}
