import UserApi from '@/api/account/user'

import FormPublicApi from '@/api/formPublic/formPublic'

import uiHelper from '@/helpers/ui'

export default {
  namespaced: true,

  state: {
    userBusinesses: [],
    userLocations: [],
    userPermissions: {},
    userAccount: {},
    userAccess: [],
    userDealershipInfo: {},
    selectedBusinessId: '',
    selectedBusinessDetails: [],
    selectedLocation: null,
    allUsers: [],
    dashboardStats: {},
    clientToken: '',
    userForms: []
  },

  getters: {
    userBusinesses: state => state['userBusinesses'],
    userLocations: state => state['userLocations'],
    selectedBusinessId: state => state['selectedBusinessId'],
    selectedLocation: state => state['selectedLocation'],
    userAccount: state => state['userAccount'],
    userAccess: state => state['userAccess'],
    userDealershipInfo: state => state['userDealershipInfo'],
    userPermissions: state => state['userPermissions'],
    isManager: state => {
      if (!state.userPermissions.Manager) return false
      return (
        state.userPermissions.Manager.approval &&
        state.userPermissions.Manager.create &&
        state.userPermissions.Manager.delete &&
        state.userPermissions.Manager.read &&
        state.userPermissions.Manager.update
      )
    },
    allAccessibleForms: state => {
      return state.userAccess
    },

    allUsers: state => state['allUsers'],
    dashboardStats: state => state['dashboardStats'],
    clientToken: state => state.clientToken,
    tableHeaders: state => {
      return state.userAccess[0].attributes.table_headers
    }
  },

  mutations: {
    setUserBusinesses(state, businesses) {
      state.userBusinesses = businesses
    },

    setUserLocations(state, locations) {
      state.userLocations = locations
    },

    setSelectedBusinessId(state, business) {
      state.selectedBusinessId = business
    },

    setSelectedLocation(state, location) {
      state.selectedLocation = location
    },

    setPermissions(state, permissions) {
      state.userPermissions = permissions
    },

    setUserAccount(state, account) {
      state.userAccount = account
    },

    setClientToken(state, token) {
      state.clientToken = token
    },

    setUserAccess(state, access) {
      state.userAccess = access
    },

    setUserForms(state, forms) {
      state.userForms = forms
    },

    setUserDealershipInfo(state, info) {
      state.userDealershipInfo = info
    },

    setAllUsers(state, users) {
      state.allUsers = users
    },

    setDashboardStats(state, stats) {
      state.dashboardStats = stats
    },

    clearAuthData(state) {
      state.setUserBusinesses = []
      state.userLocations = []
      state.userPermissions = {}
      state.selectedBusinessId = ''
      state.selectedLocation = ''
    }
  },

  actions: {
    async setSelectedBusiness({ commit }, businessId) {
      uiHelper.progressBarStatus(true)

      try {
        await UserApi.setSelectedBusiness(businessId)

        commit('setSelectedBusinessId', businessId)
      } catch (error) {
        uiHelper.showSnackbarMessage(error.response.data.msg, 'error')
      } finally {
        uiHelper.progressBarStatus(false)
      }
    },

    async createGuestRespondent({ commit }) {
      uiHelper.progressBarStatus(true)
      let clientToken = null
      try {
        let response = await FormPublicApi.createGuestRespondent()
        if (response) clientToken = response.data.payload.client_token
        sessionStorage.setItem('clientToken', clientToken)
        commit('setClientToken', clientToken)
      } catch (err) {
        uiHelper.showSnackbarMessage(err.response.data.msg, 'error')
        throw err
      } finally {
        uiHelper.progressBarStatus(false)
      }
      return clientToken
    }
  }
}
