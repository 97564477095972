import client from './_client'
import store from '../../store'

export default {
  // Get client-token
  createGuestRespondent() {
    return client({
      method: 'GET',
      url: `/api/v1/respondent/guest`
    })
  },

  // createNewSubmission(formId, clientToken) {
  //   return client({
  //     method: 'POST',
  //     url: `/api/v1/respondents/forms/${formId}`,
  //     headers: {
  //       'client-token': clientToken
  //     }
  //   })
  // },

  startNewFormSubmission({ formId }) {
    return client({
      method: 'POST',
      url: `/api/v1/submissions/forms/${formId}`,
      headers: {
        'client-token': sessionStorage.getItem('clientToken')
      },
      data: {
        ref_key_values: {}
      }
    })
  },

  getSubmissionDetails({ formId, clientToken, submissionToken }) {
    return client({
      method: 'GET',
      url: `api/v1/submissions/forms/${formId}/details`,
      headers: {
        'client-token': clientToken,
        'submission-token': submissionToken
      }
    })
  },

  submitAnswer(data) {
    return client({
      method: 'POST',
      url: `/api/v1/submissions/forms/${store.getters['form/selectedFormId']}/answer`,
      headers: {
        'submission-token': sessionStorage.getItem('submissionToken'),
        'client-token': sessionStorage.getItem('clientToken')
      },
      data
    })
  },

  submitForm(data) {
    return client({
      method: 'POST',
      url: `/api/v1/submissions/forms/${store.getters['form/selectedFormId']}/submit`,
      headers: {
        'submission-token': sessionStorage.getItem('submissionToken'),
        'client-token': sessionStorage.getItem('clientToken')
      },
      data
    })
  },
  getQuestionsBySection(formId, sectionId) {
    return client({
      method: 'GET',
      url: `/api/v1/submissions/forms/${formId}/?section_id=${sectionId}`,
      headers: {
        'client-token': sessionStorage.getItem('clientToken'),
        'submission-token': sessionStorage.getItem('submissionToken')
      }
    })
  },

  getResponsesBySection(sectionId) {
    return client({
      method: 'GET',
      url: `/api/v1/responses/?section_id=${sectionId}`,
      headers: {
        'client-token': sessionStorage.getItem('clientToken'),
        'submission-token': sessionStorage.getItem('submissionToken')
      }
    })
  },

  // getSubmissionDetails(formId) {
  getSectionResponses(section_id) {
    return client({
      method: 'GET',
      // url: `/api/v1/respondents/forms/${formId}/submission`,
      url: `/api/v1/responses/?section_id=${section_id}`,
      headers: {
        'client-token': sessionStorage.getItem('clientToken'),
        'submission-token': sessionStorage.getItem('submissionToken'),
        'auth-token': store.getters['auth/authToken']
      }
    })
  },

  getSubmissionSummary() {
    return client({
      method: 'GET',
      // url: `/api/v1/respondents/forms/${store.getters['form/selectedFormId']}/summary`,
      url: `api/v1/submissions/forms/${store.getters['form/selectedFormId']}/summary`,
      headers: {
        'client-token': sessionStorage.getItem('clientToken'),
        'submission-token': sessionStorage.getItem('submissionToken')
      }
    })
  }
}
