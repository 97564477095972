export default {
  namespaced: true,

  state: {
    snackbar: {
      visible: false,
      text: null,
      timeout: 3000,
      color: 'success'
    },
    progressBar: false
  },

  getters: {
    progressBar: state => state['progressBar']
  },

  mutations: {
    showSnackbar(state, payload) {
      state.snackbar.text = payload.text

      if (payload.timeout) state.snackbar.timeout = payload.timeout
      if (payload.color) state.snackbar.color = payload.color

      state.snackbar.visible = true
    },

    closeSnackbar(state) {
      state.snackbar.visible = false
      state.snackbar.timeout = 3000
      state.snackbar.text = null
      state.snackbar.color = 'primary'
    },

    setProgressBarStatus(state, status) {
      state.progressBar = status
    }
  },

  actions: {}
}
