<template>
  <v-app>
    <AppProgressCircular />
    <AppSnackbar />
    <v-main>
      <transition mode="out-in" name="fade">
        <router-view />
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'App',

  components: {
    AppProgressCircular: () => import('@/components/AppProgressCircular'),
    AppSnackbar: () => import('@/components/AppSnackbar')
  },

  computed: {
    ...mapGetters({
      primaryAppColor: 'business/primaryAppColor',
      secondaryAppColor: 'business/secondaryAppColor',
      accentAppColor: 'business/accentAppColor'
    })
  },

  watch: {
    primaryAppColor(val) {
      this.$vuetify.theme.themes.light.primary = val
      let bodyStyles = document.body.style
      bodyStyles.setProperty('--primary-color', val)
    },
    secondaryAppColor(val) {
      this.$vuetify.theme.themes.light.secondary = val
      let bodyStyles = document.body.style
      bodyStyles.setProperty('--secondary-color', val)
    },
    accentAppColor(val) {
      this.$vuetify.theme.themes.light.accent = val
      let bodyStyles = document.body.style
      bodyStyles.setProperty('--accent-color', val)
    }
  },

  mounted() {
    // const bodyStyles = document.body.style
    //   bodyStyles.setProperty('--primary-color', this.primaryColor)
    //   bodyStyles.setProperty('--secondary-color', this.secondaryColor)
    //   bodyStyles.setProperty('--accent-color', this.accentColor)
  }
}
</script>

<style>
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 500px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--v-primary-lighten1);
  outline: 1px solid slategrey;
}
/* Page transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.modal-content {
  transform: initial !important;
}
/* Page transition end */

.pointed-cursor {
  cursor: pointer;
}
</style>
