import client from './_client'
import store from '../../store'

const API_TOKEN = `${process.env.VUE_APP_API_TOKEN}`

export default {
  getAllBusinesses() {
    return client({
      method: 'GET',
      url: '/api/v1/users/businesses',
      headers: {
        'api-token': API_TOKEN,
        'auth-token': store.getters['auth/authToken']
      }
    })
  },

  setSelectedBusiness(businessId) {
    return client({
      method: 'PATCH',
      url: `/api/v1/users/businesses/${businessId}`,
      headers: {
        'api-token': API_TOKEN,
        'auth-token': store.getters['auth/authToken']
      }
    })
  },

  getAllLocations() {
    return client({
      method: 'GET',
      // url: '/api/v1/users/verify?app_identifier=io.unoapp.forms&withLocations=1',
      url: `/api/v1/locations/users/${store.getters['auth/userId']}`,
      headers: {
        'api-token': API_TOKEN,
        'auth-token': store.getters['auth/authToken']
      }
    })
  },

  getPermissions(locationId) {
    return client({
      method: 'GET',
      url: `/api/v1/users/permissions/locations/${locationId}?app_identifier=io.unoapp.forms2`,
      headers: {
        'api-token': API_TOKEN,
        'auth-token': store.getters['auth/authToken']
      }
    })
  }
}
