import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { createStore } from 'vuex-extensions'

import ui from './modules/ui'
import form from './modules/form'
import user from './modules/user'
import business from './modules/business'

Vue.use(Vuex)

export default createStore(Vuex.Store, {
  modules: {
    ui,
    form,
    user,
    business
  },

  plugins: [
    createPersistedState({
      reducer: persistedState => {
        const stateFilter = Object.assign({}, persistedState)
        const blackList = ['ui']
        // For removing particular Vuex module from being persisted
        blackList.forEach(item => {
          delete stateFilter[item]
        })

        return stateFilter
      }
    })
  ]
})
