import { mapActions } from 'vuex'

export default {
  computed: {
    $_userId() {
      if (this.$_isPublicForm) return ''
      return this.$store.getters['auth/userId']
    },

    $_isPublicForm() {
      return this.$route.name === 'FormPublic'
    }
  },

  methods: {
    ...mapActions({
      $_submitSingleAnswer: 'form/submitSingleAnswer'
    }),

    $_scrollToTop() {
      return window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
}
