import Vue from 'vue'
import PubNubVue from 'pubnub-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'

import './assets/css/global.css'
import Vuelidate from 'vuelidate'
import globalMixin from '@/mixins/global'
import VueCurrencyInput from 'vue-currency-input'
import {
  applyPolyfills as fecApplyPolyfills,
  defineCustomElements as formsEngineComponent
} from '@unoapp/forms-engine-component/loader'

Vue.use(PubNubVue, {
  subscribeKey: 'sub-c-2460de5e-b6cf-11e8-8c7a-8a442d951856',
  publishKey: 'pub-c-3feb99a6-35e3-441e-871f-f3b765e83471',
  ssl: true
})

const VueCurrencyInputOptions = {
  globalOptions: { currency: 'CAD', precision: 2, 'allow-negative': false }
}
// Tell Vue to ignore all components defined in the test-components
// package. The regex assumes all components names are prefixed
// 'test'
Vue.config.ignoredElements = [/test-\w*/]
// Bind the custom elements to the window object
fecApplyPolyfills().then(() => {
  formsEngineComponent()
})
Vue.config.productionTip = false

Vue.use(Vuelidate)
Vue.use(VueCurrencyInput, VueCurrencyInputOptions)

Vue.mixin(globalMixin)
// defineCustomElements(window)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
