import BusinessApi from '@/api/account/business'
import uiHelper from '@/helpers/ui'

export default {
  namespaced: true,

  state: {
    branding: {}
  },

  getters: {
    primaryAppColor(state) {
      if (Object.keys(state.branding).length === 0) return '#db563f'
      return state.branding.primary_color
    },

    secondaryAppColor(state) {
      if (Object.keys(state.branding).length === 0) return '#FAFAFA'
      return state.branding.secondary_color
    },

    accentAppColor(state) {
      if (Object.keys(state.branding).length === 0) return '#14B9D6'
      return state.branding.accent_color
    },

    brandLogo(state) {
      return state.branding.logo_url
    }
  },

  mutations: {
    setBranding(state, colors) {
      state.branding = colors
    }
  },

  actions: {
    async getBusinessDetails({ commit }, businessId) {
      uiHelper.progressBarStatus(true)

      try {
        const response = await BusinessApi.getBusinessDetails(businessId)
        commit('setBranding', response.data.payload.branding)
      } catch (error) {
        throw error
      } finally {
        uiHelper.progressBarStatus(false)
      }
    },

    async getBusinessDetailsBySlug({ commit }, businessSlug) {
      uiHelper.progressBarStatus(true)

      try {
        const response = await BusinessApi.getBusinessDetailsBySlug(
          businessSlug
        )
        commit('setBranding', response.data.payload.branding)
      } catch (error) {
        commit('setBranding', {})
      } finally {
        uiHelper.progressBarStatus(false)
      }
    }
  }
}
