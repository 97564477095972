import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import store from '../store'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: store.getters['business/primaryAppColor'],
        secondary: store.getters['business/secondaryAppColor'],
        accent: store.getters['business/accentAppColor'],
        error: '#D0021B',
        warning: '#FFC200',
        info: '#666666',
        success: '#5CB85C',
        black: '#000000',
        bloodred: '#af371f',
        darkGrey: '#4b4b4b'
      }
    }
  }
})
